import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { SuspensionFormComponent } from '../components/suspension-form/suspension-form.component';
import { Suspension, SuspensionFile } from '../interfaces/suspension.model';

@Injectable({ providedIn: 'root' })
export class SuspensionsService {

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private dialog: MatDialog
  ) {
    this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/suspension`;
  }

  getSuspensionsByWorker(workerId: number, maxResult: number, skipCount: number) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        workerId,
        MaxResultCount: maxResult,
        SkipCount: skipCount
      }
    }) 
    return this.http.get<any>(this.apiURL, { params });
  }

  getSuspensionById(suspensionId: string) {
    return this.http.get<any>(`${this.apiURL}/${suspensionId}`).toPromise();
  }
  
  update(suspension: Suspension) {
    return this.http.put<any>(`${this.apiURL}/${suspension.id}`, suspension);
  }

  create(suspension: Suspension) {
    return this.http.post<Suspension>(`${this.apiURL}`, suspension);
  }

  addFiles(suspensionId: string, files: Array<File>) {
    const formData: FormData = new FormData();
    files?.forEach((f) => {
      formData.append('files', f);
    });
    return this.http.post<SuspensionFile[]>(`${this.apiURL}/${suspensionId}/uploadFiles`, formData).toPromise();
  }
  
  removeFiles(filesIds: Array<string>) {
    return this.http.post<any>(`${this.apiURL}/filesToRemove`, { suspensionFileIds: filesIds }).toPromise();
  }

  remove(suspension: Suspension) {
    return this.http.delete(`${this.apiURL}/${suspension.id}`);
  }

  openSuspensionForm(workerId: number, suspension?: Suspension): void {
    this.dialog.open(SuspensionFormComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				suspension: suspension,
				workerId: workerId
			}
		});
  }
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
import { enum2Array } from 'src/app/shared/utils/utils';
import { DebitAmountType } from '../../enums/debit-amount-type.enum';
import { DebitFrequencyType } from '../../enums/debit-frequency-type.enum';
import { DebitFrequency } from '../../enums/debit-frequency.enum';
import { WorkerDebit } from '../../interfaces/worker-debit.interface';
import { DebitsService } from '../../services/debits.service';
import { AddWorkerDebit, UpdateWorkerDebit } from '../../state/worker.actions';

@Component({
  selector: 'app-debit-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    DropdownComponent,
    TextInputComponent,
    DatePickerComponent,
    DialogFooterComponent
  ],
  templateUrl: './debit-form.component.html'
})
export class DebitFormComponent implements OnInit, OnDestroy {

  title: string;
  debitForm: UntypedFormGroup;
  debitTypesOptions: SelectOption[];
  debitAmountTypeOptions: SelectOption[];
  debitFrequencyOptions: SelectOption[];
  debitFrequencyTypeOptions: SelectOption[];
  DebitFrequency: typeof DebitFrequency = DebitFrequency;
  private unsubscribe$: Subject<void>;

  @Select(state => state.worker.saving) isSaving$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<DebitFormComponent>,
    private dialog: MatDialog,
    private store: Store,
    private debitService: DebitsService,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      debit: WorkerDebit;
      workerId: number;
      callbackSuccess: () => void;
      callbackError: (error) => void;
    }
  ) {
    this.title = '';
    this.debitTypesOptions = [];
    this.debitAmountTypeOptions = enum2Array(DebitAmountType);
    this.debitFrequencyOptions = enum2Array(DebitFrequency);
    this.debitFrequencyTypeOptions = enum2Array(DebitFrequencyType);
    this.unsubscribe$ = new Subject<void>();
    this.dialogRef.disableClose = true;
    this.subscribeBackdropDialog();
  }

  private subscribeBackdropDialog(): void {
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialog.open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          message: `Are you sure you want to leave?`,
          onConfirm: () => {
            this.dialog.closeAll();
            this.dialogRef.close();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.getDebitTypes();
    this.setModalTitle();
    this.createForm();
  }

  private getDebitTypes(): void {
    this.debitService.getDebitTypes().subscribe(
			(response) => {
        this.debitTypesOptions = response.items.map((e) => {
          return {
            value: e.id,
            text: e.name
          }
        });
			}
		);
  }

  private setModalTitle(): void {
    this.title = this.data?.debit ? 'Edit Debit' : 'Create New Debit';
  }

  private createForm(): void {
    const isRecurrent = this.data.debit?.debitFrequency === DebitFrequency.Repeating;
    this.debitForm = new UntypedFormGroup({
      "debitTypeId": new UntypedFormControl(this.data.debit?.debitTypeId || null, [Validators.required]),
      "amount": new UntypedFormControl(this.data.debit?.amount || null, [Validators.required]),
      "debitAmountType": new UntypedFormControl(this.data.debit?.debitAmountType, [Validators.required]),
      "debitFrequency": new UntypedFormControl(this.data.debit?.debitFrequency, [Validators.required]),
      "debitFrequencyType": new UntypedFormControl({
        value: this.data.debit?.debitFrequencyType,
        disabled: !isRecurrent
      }),
      "paymentDate": new UntypedFormControl({
        value: this.data.debit?.paymentDate || null,
        disabled: isRecurrent
      }, [Validators.required]),
      "effectiveStartDate": new UntypedFormControl({
        value: this.data.debit?.effectiveStartDate || null,
        disabled: !isRecurrent
      }, [Validators.required]),
      "effectiveEndDate": new UntypedFormControl({
        value: this.data.debit?.effectiveEndDate || null,
        disabled: !isRecurrent
      }),
      "paymentCoverStartDate": new UntypedFormControl(this.data.debit?.paymentCoverStartDate || null),
      "paymentCoverEndDate": new UntypedFormControl(this.data.debit?.paymentCoverEndDate || null),
      "notes": new UntypedFormControl(this.data.debit?.notes || null)
    });
    this.cdRef.detectChanges();
    this.subscribeForm();
  }

  private subscribeForm(): void {
    this.debitForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (value) => {
        const effectiveStart = value.effectiveStartDate;
        const effectiveEnd = value.effectiveEndDate;
        const effectiveRangeErrors: Object = (effectiveStart && effectiveEnd && new Date(effectiveStart) > new Date(effectiveEnd)) ? { invalidDate: true } : null;
        this.debitForm.get('effectiveStartDate').setErrors(effectiveRangeErrors);
        this.debitForm.get('effectiveEndDate').setErrors(effectiveRangeErrors);

        const coverStart = value.paymentCoverStartDate;
        const coverEnd = value.paymentCoverEndDate;
        const coverRangeErrors: Object = (coverStart && coverEnd && new Date(coverStart) > new Date(coverEnd)) ? { invalidDate: true } : null;
        this.debitForm.get('paymentCoverStartDate').setErrors(coverRangeErrors);
        this.debitForm.get('paymentCoverEndDate').setErrors(coverRangeErrors);
        
        if (value.debitFrequency === DebitFrequency.Repeating) {
          this.debitForm.get('debitFrequencyType').enable({ emitEvent: false });
          this.debitForm.get('effectiveStartDate').enable({ emitEvent: false });
          this.debitForm.get('effectiveEndDate').enable({ emitEvent: false });
          this.debitForm.get('paymentDate').disable({ emitEvent: false });
          this.debitForm.get('paymentDate').setValue(null, { emitEvent: false });
        } else if (value.debitFrequency === DebitFrequency.OneTime) {
          this.debitForm.get('paymentDate').enable({ emitEvent: false });
          this.debitForm.get('debitFrequencyType').disable({ emitEvent: false });
          this.debitForm.get('effectiveStartDate').disable({ emitEvent: false });
          this.debitForm.get('effectiveEndDate').disable({ emitEvent: false });
          this.debitForm.patchValue({
            debitFrequencyType: null,
            effectiveStartDate: null,
            effectiveEndDate: null
          }, { emitEvent: false });
        }
      }
    )
  }

  save(): void {
    this.dialogRef.disableClose = true;
    this.data.debit ? this.dispatchUpdateDebit() : this.dispatchAddDebit();
  }

  private dispatchUpdateDebit(): void {
    const debit: WorkerDebit = {
      ...this.debitForm.getRawValue(),
      id: this.data.debit.id,
      workerId: this.data.workerId,
      debitAmount: parseFloat(this.debitForm.value.debitAmount)
    };
    this.store.dispatch(new UpdateWorkerDebit(
      debit,
      this.data.callbackSuccess,
      this.data.callbackError
    ));
  }

  private dispatchAddDebit(): void {
    this.store.dispatch(new AddWorkerDebit(
      { 
        ...this.debitForm.getRawValue(),
        workerId: this.data.workerId,
        debitAmount: parseFloat(this.debitForm.value.debitAmount)
      },
      this.data.callbackSuccess,
      this.data.callbackError
    ));
  }
  
  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { enterAnimation } from 'src/app/core/animations';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { InputFilesComponent } from 'src/app/shared/components/input-files/input-files.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { InputFile } from 'src/app/shared/interfaces/input-file.interface';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
import { Incident } from '../../interfaces/incident.model';
import { AddWorkerIncident, UpdateWorkerIncident } from '../../state/worker.actions';
;

@Component({
  selector: 'app-incident-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    TextInputComponent,
    DropdownComponent,
    DatePickerComponent,
    InputFilesComponent,
    DialogFooterComponent
  ],
  templateUrl: './incident-form.component.html',
  animations: [enterAnimation]
})
export class IncidentFormComponent implements OnInit {

  @Select(state => state.worker.saving) isSaving$: Observable<boolean>;
  @Select(state => state.prefillData.allEmployers) employers$: Observable<boolean>;
  @Select(state => state.prefillData.allTerminals) terminals$: Observable<SelectOption[]>;
  @Select(state => state.worker.incidentTypes) incidentTypes$: Observable<SelectOption[]>;
  title: string;
  incidentForm: UntypedFormGroup;
  incidentFiles: Array<InputFile>;
  filesIdsToDelete: Array<string>;

  constructor(        
    private dialogRef: MatDialogRef<IncidentFormComponent>,
    private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      incident: Incident,
      workerId: number,
      onUpdate: () => void
    }
  ) { 
    this.dialogRef.disableClose = true;
    this.incidentFiles = [];
    this.filesIdsToDelete = [];
    this.subscribeBackdropDialog();
  }

  private subscribeBackdropDialog(): void {
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialog.open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          message: `Are you sure you want to leave?`,
          onConfirm: () => {
            this.dialog.closeAll();
            this.dialogRef.close();
          }
        }
      });
    })
  }

  ngOnInit(): void {
    this.createForm();
    this.setTitle();
  }

  private createForm(): void {
    this.incidentForm = new UntypedFormGroup({
      "employerId": new UntypedFormControl(this.data.incident?.employerId || null, [Validators.required]),
      "reportedByName": new UntypedFormControl({ value: this.data.incident?.reportedByName || null, disabled: true }),
      "terminalId": new UntypedFormControl(this.data.incident?.terminalId || null, [Validators.required]),
      "incidentDate": new UntypedFormControl(this.data.incident?.incidentDate || null, [Validators.required]),
      "incidentType": new UntypedFormControl(this.data.incident?.incidentType, [Validators.required]),
      "comments": new UntypedFormControl(this.data.incident?.comments || null)
    });
    if (this.data?.incident) {
      this.incidentFiles = this.data.incident.workerIncidentFiles.map((f) => {
        return {
          name: f.fileName,
          file: null,
          url: f.fileUri,
          id: f.id,
        };
      })
    }
  }

  private setTitle(): void {
    this.title = this.data.incident ? 'Edit incident' : 'Create New Incident';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  setFiles(files: Array<InputFile>): void {
		this.incidentFiles = files;
	}

  deleteFile(file: InputFile): void {
    if (file.id) {
      this.filesIdsToDelete.push(file.id); 
    }
  }

  save(): void {
    this.dialogRef.disableClose = true;
    const incident: Incident = this.incidentForm.value;
    incident.id = this.data?.incident?.id;
    incident.workerId = this.data.workerId;
    const files: Array<File> = this.incidentFiles.map((f) => { return f.file; });
    if (this.data.incident) {
      this.store.dispatch(new UpdateWorkerIncident(incident, files.filter((f) => { return !!f }), this.filesIdsToDelete, this.data.onUpdate));
    } else {
      this.store.dispatch(new AddWorkerIncident(incident, files, this.data.onUpdate));
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { API_URL } from 'src/environments/settings';
import { PaymentFormComponent } from '../components/payment-form/payment-form.component';
import { WorkerPayment } from '../interfaces/worker-payment.interface';

@Injectable({ providedIn: 'root' })
export class PaymentsService {

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private dialog: MatDialog,
    private _notificationService: NotificationService
  ) {
    this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/workerPayment`;
  }

  getPaymentsByWorkerId(workerId: number, maxResult: number, skipCount: number) {
    return this.http.get<any>(`${this.apiURL}?workerId=${workerId}&MaxResultCount=${maxResult}&SkipCount=${skipCount}`);
  }

  getPaymentTypes() {
    return this.http.get<any>(`${this.apiURL}/paymentTypes`).toPromise();
  }
  
  updatePayment(payment: WorkerPayment) {
    return this.http.put<any>(`${this.apiURL}/${payment.id}`, payment).toPromise();
  }

  createPayment(payment: WorkerPayment) {
    return this.http.post<WorkerPayment>(`${this.apiURL}`, payment).toPromise();
  }

  removePayment(paymentId: string) {
    return this.http.delete(`${this.apiURL}/${paymentId}`).toPromise();
  }

  openPaymentForm(workerId: number, payment?: WorkerPayment): void {
    this.dialog.open(PaymentFormComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				payment: payment,
				workerId: workerId,
        callbackSuccess: () => {
          const successMessage: string = payment ? "The payment was successfully updated." : "The payment was successfully created.";
          this._notificationService.showSuccess(successMessage);
          this.dialog.closeAll();
        },
        callbackError: (error) => {
          const errorMessage: string = error?.error?.error?.message ? 
            error.error.error.message : 
            payment ? 
              "There was a problem updating the payment. Please try again." : 
              "There was a problem creating the payment. Please try again.";
          this._notificationService.showError(errorMessage);
        }
			}
		});
  }
}

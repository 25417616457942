import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { API_URL } from 'src/environments/settings';
import { DebitFormComponent } from '../components/debit-form/debit-form.component';
import { WorkerDebit } from '../interfaces/worker-debit.interface';

@Injectable({ providedIn: 'root' })
export class DebitsService {

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private dialog: MatDialog,
    private _notificationService: NotificationService
  ) {
    this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/workerDebit`;
  }

  getDebitsByWorkerId(workerId: number, maxResult: number, skipCount: number) {
    return this.http.get<any>(`${this.apiURL}?workerId=${workerId}&MaxResultCount=${maxResult}&SkipCount=${skipCount}`);
  }

  getDebitTypes() {
    return this.http.get<any>(`${this.env.getValueTransferState(API_URL)}/app/debitType`);
  }
  
  updateDebit(debit: WorkerDebit) {
    return this.http.put<any>(`${this.apiURL}/${debit.id}`, debit).toPromise();
  }

  createDebit(debit: WorkerDebit) {
    return this.http.post<WorkerDebit>(`${this.apiURL}`, debit).toPromise();
  }

  removeDebit(debitId: string) {
    return this.http.delete(`${this.apiURL}/${debitId}`).toPromise();
  }

  openDebitForm(workerId: number, debit?: WorkerDebit): void {
    this.dialog.open(DebitFormComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				debit: debit,
				workerId: workerId,
        callbackSuccess: () => {
          const successMessage: string = debit ? "The debit was successfully updated." : "The debit was successfully created.";
          this._notificationService.showSuccess(successMessage);
          this.dialog.closeAll();
        },
        callbackError: (error) => {
          const errorMessage: string = error?.error?.error?.message ? 
            error.error.error.message : 
            debit ? 
              "There was a problem updating the debit. Please try again." : 
              "There was a problem creating the debit. Please try again.";
          this._notificationService.showError(errorMessage);
        }
			}
		});
  }
}

<h3 class="font-extrabold mb-8">{{ title }}</h3>

@if (error$ | async) {
	<h3 class="text-red-500 py-4">
		Something went wrong updating the suspension
	</h3>
}

<form (ngSubmit)="save()" class="overflow-x-hidden" [formGroup]="suspensionForm">
	<fieldset [disabled]="isSaving$ | async">
		<div class="grid grid-cols-2 mb-4 gap-2">
			<app-dropdown [placeholder]="'Select Employer'"
										[required]="true"
										[label]="'Employer'"
										[fullWidth]="true"
										[options]="employers"
										[search]="true"
										[formControl]="suspensionForm.get('employerId')" />

			<app-dropdown [placeholder]="'Select Union'"
										[required]="true"
										[label]="'Union'"
										[fullWidth]="true"
										[options]="unions"
										[search]="true"
										[formControl]="suspensionForm.get('unionId')" />
		</div>

		<div class="grid grid-cols-2 mb-4 gap-2">
			<app-dropdown [placeholder]="'Select Suspension Type'"
										[required]="true"
										[label]="'Suspension Type'"
										[fullWidth]="true"
										[options]="suspensionTypes$ | async"
										[search]="true"
										[formControl]="suspensionForm.get('suspensionTypeId')" />

			<app-dropdown [placeholder]="'Select Duration'"
										[label]="'Number of hours'"
										[fullWidth]="true"
										[options]="hoursOptions"
										[formControl]="suspensionForm.get('numberOfHours')" />
		</div>

		<div class="grid grid-cols-2 mb-4 gap-2">
			<app-date-picker
				[placeholder]="'Select Effective Date'"
				[required]="true"
				[label]="'Effective Date'"
				class="block"
				[formControl]="suspensionForm.get('effectiveDate')" />

			<app-time-input
				[label]="'Effective Time'"
				[placeholder]="'Select Effective Time'"
				class="block mb-4"
				[formControl]="suspensionForm.get('effectiveTime')" />
		</div>

		<div class="grid grid-cols-2 mb-4 gap-2">
			<app-date-picker 
				[placeholder]="'Select Expiration Date'"
				[required]="true"
				[label]="'Expiration Date'"
				[formControl]="suspensionForm.get('expirationDate')"
				class="block" />

			<app-time-input
				[label]="'Expiration Time'"
				[placeholder]="'Select Expiration Time'"
				class="block mb-4"
				[formControl]="suspensionForm.get('expirationTime')" />
		</div>

		<div class="w-full mb-4">
			<label class="base-input-label w-full mt-2 mr-1">
        Comment
        <textarea [placeholder]="'Type Comment'"
									[formControl]="suspensionForm.get('comments')"
                  cols="40"
                  rows="3"
                  class="border-2 rounded mt-1 text-gray-600 p-4">
        </textarea>
      </label>
		</div>

		<div class="w-full">
			<app-input-files [label]="'Documents'"
											 [acceptedTypes]="'.pdf'"
                       [limitSize]="25"
                       [limitCount]="5"
											 [multiple]="true"
											 [showList]="true"
                       [initialFiles]="suspensionFiles"
                       [disabled]="isSaving$ | async"
											 (updated)="setFiles($event)"
											 (deleted)="deleteFile($event)" />
		</div>

		<app-dialog-footer [isSaving]="isSaving$ | async"
											 [submitActionName]="data.suspension ? 'Save' : 'Add'"
											 [valid]="suspensionForm.valid"
											 (cancelAction)="closeDialog()" />
	</fieldset>
</form>

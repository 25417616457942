import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Worker } from 'src/app/core/interfaces/worker.interface';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { CertificationPreRequisite } from 'src/app/features/admin/certifications/models/certification.model';
import { API_URL } from 'src/environments/settings';
import { ContractDates } from '../../roster/interfaces/contract-dates.interface';
import { WorkerExperience } from '../../roster/interfaces/worker-experience.interface';
import { WorkerUnions } from '../../roster/interfaces/worker-union.interface';
import { WorkerCertification } from '../interfaces/worker-certification.model';

@Injectable({ providedIn: 'root' })
export class WorkerService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  create(worker: Worker) {
		return this.http.post<any>(`${this.apiUrl}/app/worker`, worker);
	}

	update(worker: Worker) {
		return this.http.put<any>(`${this.apiUrl}/app/worker/${worker.id}`, worker);
	}

	uploadImage(workerId: number, file: File) {
		const formData: FormData = new FormData();
    formData.append("files", file, file.name);
		return this.http.post<any>(`${this.apiUrl}/app/workerImage/uploadProfileImage/${workerId}`, formData);
	}

	removeImage(workerId: number) {
		return this.http.delete<any>(`${this.apiUrl}/app/workerImage/profileImage/${workerId}`);
	}

  getWorkerById(id: number) {
		return this.http.get<any>(`${this.apiUrl}/app/worker/${id}`);
	}

	getWorkerPrefillOptions() {
		return this.http.post<any>(`${this.apiUrl}/app/prefillProvider/getPrefillData`, {
			includeRequests: [
        {
          entityName: "Gender",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "genders"
        },
        {
          entityName: "Ethnicity",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "races"
        }
			]
		}).pipe(
			map(
				(response: any) => {
					let combos = {};
					response.prefillData.forEach(d => {
						combos[d.filterName] = d.filterData.map((item) => {
							return { text: item.value, value: item.key };
						});
					});
					return combos;
				}
			)
		);
	}

  updateWorkerExperience(workerId: number, workerExp: WorkerExperience[]) {
		return this.http.post<any>(
			`${this.apiUrl}/app/workerExperience/workerExperienceUpdateOrCreate`,
			{ workerId: workerId, workerExperiences: workerExp }
		);
	}

  updateOrCreateWorkerUnions(workerId: number, workerUnions: WorkerUnions[]) {
		return this.http.post<any>(`${this.apiUrl}/app/worker/workerUnionsUpdateOrCreate`, {
			workerId: workerId,
			workerUnions: workerUnions
		});
	}

  getWorkerCertifications(workerId: number) {
		if (this.env.isBrowser)
			return this.http.get<any>(`${this.apiUrl}/app/workerCertification/${workerId}/certifications`);
	}

	addCertificationToWorker(workerId: number, certificationId: string) {
		return this.http.post<any>(`${this.apiUrl}/app/workerCertification/multipleAdd`, {
			workerId: workerId,
			certificationId: certificationId
		});
	}

	getCertificationPreRequisites(certificationId: string) {
		return this.http.get<CertificationPreRequisite[]>(`${this.apiUrl}/app/certifications/preRequisites/${certificationId}`);
	}

  deleteWorkerUnionId(id: string) {
		return this.http.delete(`${this.apiUrl}/app/worker/${id}/workerUnion`);
	}

  getWorkerCommuncations(workerId: number) {
    return this.http.get<any>(`${this.apiUrl}/app/worker/workerCommunicationList?workerId=${workerId}`);
	}

  editCertificationToWorker(workerCertificationId: string, certificationId: string, workerCertification: WorkerCertification) {
		return this.http.put<any>(`${this.apiUrl}/app/workerCertification/${workerCertificationId}/multipleUpdate`, {
			workerId: workerCertification.workerId,
			certifiedDate: workerCertification.certifiedDate,
			expirationDate: workerCertification.expirationDate,
			certificationId
		});
	}

	removeCertificationToWorker(workerCertificationId: string, workerId: number, certificationId: string) {
		return this.http.delete<any>(
			`${this.apiUrl}/app/workerCertification/${workerCertificationId}/multipleDelete?workerId=${workerId}&certificationId=${certificationId}`
		);
	}

  getWorkerProfileHistory(workerId: number) {
    return this.http.get<any>(`${this.apiUrl}/app/worker/${workerId}/workerProfileHistory`);
	}

	getContractDates(): Observable<ContractDates[]> {
    return this.http.get<any>(`${this.apiUrl}/app/worker/contractDates`);
	}

}

<h3 class="font-extrabold mb-6">{{ title }}</h3>

<form [formGroup]="incidentForm" (ngSubmit)="save()" class="overflow-x-hidden">
  <fieldset [disabled]="isSaving$ | async">
    <div class="grid grid-cols-2 mb-4">
      <app-dropdown [placeholder]="'Select Employer'"
                    [required]="true"
                    [label]="'Employer'"
                    [fullWidth]="true"
                    [options]="employers$ | async"
                    [search]="true"
                    [formControl]="incidentForm.get('employerId')"
                    class="block mr-2" />

      <app-dropdown [placeholder]="'Select Terminal'"
                    [required]="true"
                    [label]="'Terminal'"
                    [fullWidth]="true"
                    [options]="terminals$ | async"
                    [search]="true"
                    [formControl]="incidentForm.get('terminalId')"
                    class="block ml-2" />
    </div>

    <div class="grid grid-cols-2 mb-4">
      <app-date-picker [placeholder]="'Select Incident Date'"
                      [required]="true"
                      [label]="'Incident Date'"
                      [formControl]="incidentForm.get('incidentDate')"
                      class="block mr-2" />

      <app-dropdown [placeholder]="'Select Incident Type'"
                    [required]="true"
                    [label]="'Incident Type'"
                    [fullWidth]="true"
                    [options]="incidentTypes$ | async"
                    [formControl]="incidentForm.get('incidentType')"
                    class="block ml-2" />
    </div>

    @if (data.incident) {
      <div class="grid grid-cols-2 mb-4">
        <app-text-input [placeholder]="'Reported By'"
                        [label]="'Reported By'"
                        [disabled]="true"
                        [formControl]="incidentForm.get('reportedByName')"
                        class="block mr-2" />
      </div>
    }

    <div class="mb-4">
      <label class="base-input-label w-full mt-2 mr-1">
        Comment
        <textarea [placeholder]="'Type Comment'"
                  [formControl]="incidentForm.get('comments')"
                  cols="40"
                  rows="3"
                  class="border-2 rounded mt-1 text-gray-600 p-4">
        </textarea>
      </label>
    </div>

    <div class="w-full">
			<app-input-files [label]="'Documents'"
											 [acceptedTypes]="'.pdf'"
                       [limitSize]="25"
                       [limitCount]="5"
											 [multiple]="true"
											 [showList]="true"
                       [initialFiles]="incidentFiles"
                       [disabled]="isSaving$ | async"
                       (updated)="setFiles($event)"
                       (deleted)="deleteFile($event)" />
		</div>

    <app-dialog-footer [isSaving]="isSaving$ | async"
                       [submitActionName]="data.incident ? 'Save' : 'Add'"
                       [valid]="incidentForm.valid"
                       (cancelAction)="closeDialog()" />
  </fieldset>
</form>

<h3 class="font-extrabold mb-6">{{ title }}</h3>

<form (ngSubmit)="save()" [formGroup]="paymentForm" class="overflow-x-hidden">
    <fieldset [disabled]="isSaving$ | async">
        <div class="grid grid-cols-3 mb-4 gap-3">
            <app-dropdown
                [placeholder]="'Select Payment Type'"
                [required]="true"
                [label]="'Payment Type'"
                [fullWidth]="true"
                [options]="paymentTypes"
                [formControl]="paymentForm.get('paymentTypeId')"
                class="block" />

            <app-text-input
                [placeholder]="'Select Amount'"
                [label]="'Amount'" type="number"
                [required]="true"
                [formControl]="paymentForm.get('paymentAmount')" class="block" />

            <app-date-picker
                [placeholder]="'Select Payment Date'"
                [label]="'Payment Date'"
                [required]="true"
                [formControl]="paymentForm.get('paymentDate')" class="block" />
        </div>

        <div class="grid grid-cols-3 mb-4 gap-3">
            <app-date-picker
                [placeholder]="'Select Effective Start'"
                [label]="'Effective Start Date'"
                [formControl]="paymentForm.get('effectiveStartDate')" class="block" />

            <app-date-picker
                [placeholder]="'Select Effective End'"
                [label]="'Effective End Date'"
                [formControl]="paymentForm.get('effectiveEndDate')" class="block" />
        </div>

        <div class="grid grid-cols-6">
            <label class="col-span-6 base-input-label w-full mt-2 mr-1">
                Notes
                <textarea formControlName="notes" cols="40" placeholder="Type Notes" maxLength="500"
                    class="border-2 rounded mt-1 text-gray-600 p-4">
				</textarea>
            </label>
        </div>
        
        <app-dialog-footer
            [isSaving]="isSaving$ | async"
            [submitActionName]="data.payment ? 'Save' : 'Add'"
            [valid]="paymentForm.valid"
            (cancelAction)="closeDialog()" />
    </fieldset>
</form>
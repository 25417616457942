import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
import { WorkerPayment, WorkerPaymentType } from '../../interfaces/worker-payment.interface';
import { PaymentsService } from '../../services/payments.service';
import { AddWorkerPayment, UpdateWorkerPayment } from '../../state/worker.actions';

@Component({
  selector: 'app-payment-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    DatePickerComponent,
    TextInputComponent,
    DropdownComponent,
    DialogFooterComponent
  ],
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit, OnDestroy {

  title: string;
  paymentForm: UntypedFormGroup;
  paymentTypes: Array<SelectOption>;
  private unsubscribe$: Subject<void>;

  @Select(state => state.worker.saving) isSaving$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<PaymentFormComponent>,
    private dialog: MatDialog,
    private store: Store,
    private paymentService: PaymentsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      payment: WorkerPayment;
      workerId: number;
      callbackSuccess: () => void;
      callbackError: (error) => void;
    }
  ) {
    this.title = '';
    this.paymentTypes = [];
    this.unsubscribe$ = new Subject<void>();
    this.subscribeBackdropDialog();
  }

  private subscribeBackdropDialog(): void {
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialog.open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          message: `Are you sure you want to leave?`,
          onConfirm: () => {
            this.dialog.closeAll();
            this.dialogRef.close();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.getPaymentTypes();
    this.setModalTitle();
    this.createForm();
  }

  private getPaymentTypes(): void {
    this.paymentService.getPaymentTypes().then(
			(response: Array<WorkerPaymentType>) => {
        this.paymentTypes = response.map((e) => {
          return {
            value: e.id,
            text: e.name
          }
        });
			}
		);
  }

  private setModalTitle(): void {
    this.title = this.data?.payment ? 'Edit Payment' : 'Create New Payment';
  }

  private createForm(): void {
    this.paymentForm = new UntypedFormGroup({
      "paymentTypeId": new UntypedFormControl(this.data.payment?.paymentTypeId || '', [Validators.required]),
      "paymentAmount": new UntypedFormControl(this.data.payment?.paymentAmount || '', [Validators.required]),
      "paymentDate": new UntypedFormControl(this.data.payment?.paymentDate || '', [Validators.required]),
      "effectiveStartDate": new UntypedFormControl(this.data.payment?.effectiveStartDate || ''),
      "effectiveEndDate": new UntypedFormControl(this.data.payment?.effectiveEndDate || ''),
      "notes": new UntypedFormControl(this.data.payment?.notes || '')
    });
    this.paymentForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (value) => {
        const effectiveStart = value.effectiveStartDate;
        const effectiveEnd = value.effectiveEndDate;
        const errors: Object = (effectiveStart && effectiveEnd && new Date(effectiveStart) > new Date(effectiveEnd)) ? { invalidDate: true } : null;
        this.paymentForm.get('effectiveStartDate').setErrors(errors);
        this.paymentForm.get('effectiveEndDate').setErrors(errors);
      }
    )
  }

  save(): void {
    this.dialogRef.disableClose = true;
    this.data.payment ? this.dispatchUpdatePayment() : this.dispatchAddPayment();
  }

  private dispatchUpdatePayment(): void {
    const payment: WorkerPayment = {
      ...this.paymentForm.value,
      id: this.data.payment.id,
      workerId: this.data.workerId,
      paymentAmount: parseInt(this.paymentForm.value.paymentAmount)
    };
    this.store.dispatch(new UpdateWorkerPayment(
      payment,
      this.data.callbackSuccess,
      this.data.callbackError
    ));
  }

  private dispatchAddPayment(): void {
    this.store.dispatch(new AddWorkerPayment(
      { 
        ...this.paymentForm.value,
        workerId: this.data.workerId,
        paymentAmount: parseInt(this.paymentForm.value.paymentAmount)
      },
      this.data.callbackSuccess,
      this.data.callbackError
    ));
  }
  
  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

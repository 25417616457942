import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { WorkerDocument } from '../interfaces/worker-document.interface';

@Injectable({ providedIn: 'root' })
export class DocumentsService {

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/workerDocument`;
  }

  getDocumentsByWorkerId(workerId: number, maxResult: number, skipCount: number) {
    return this.http.get<any>(`${this.apiURL}?workerId=${workerId}&MaxResultCount=${maxResult}&SkipCount=${skipCount}`);
  }
  
  getDocumentById(documentId: string) {
    return this.http.get<any>(`${this.apiURL}/${documentId}`).toPromise();
  }
  
  updateDocument(document: WorkerDocument) {
    return this.http.put<any>(`${this.apiURL}/${document.id}`, document).toPromise();
  }

  createDocument(document: WorkerDocument) {
    return this.http.post<WorkerDocument>(`${this.apiURL}`, document).toPromise();
  }

  addFiles(documentId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('files', file);
    return this.http.post<any>(`${this.apiURL}/${documentId}/uploadFiles`, formData).toPromise();
  }

  removeFiles(filesIds: Array<string>) {
    return this.http.post<any>(`${this.apiURL}/filesToRemove`, { workerDocumentFileIds: filesIds }).toPromise();
  }

  removeDocument(documentId: string) {
    return this.http.delete(`${this.apiURL}/${documentId}`).toPromise();
  }
}

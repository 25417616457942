import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { API_URL } from 'src/environments/settings';
import { IncidentFormComponent } from '../components/incident-form/incident-form.component';
import { Incident } from '../interfaces/incident.model';

@Injectable({ providedIn: 'root' })
export class IncidentsService {

  private workerIncidentUrl: string;
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private dialog: MatDialog,
    private _notificationService: NotificationService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app`;
    this.workerIncidentUrl = `${this.apiUrl}/workerIncident`;
  }

  getIncidentsByWorkerId(workerId: number) {
    return this.http.get<any>(`${this.workerIncidentUrl}?workerId=${workerId}`);
  }

  getIncidentsById(incidentId: string) {
    return this.http.get<any>(`${this.workerIncidentUrl}/${incidentId}`).toPromise();
  }

  update(incident: Incident) {
    return this.http.put<any>(`${this.workerIncidentUrl}/${incident.id}`, incident);
  }

  create(incident: Incident) {
    return this.http.post<any>(`${this.workerIncidentUrl}`, incident).toPromise();
  }

  addFiles(incidentId: string, files: Array<File>) {
    const formData: FormData = new FormData();
    files?.forEach((f) => {
      formData.append('files', f);
    });
    return this.http.post<any>(`${this.workerIncidentUrl}/${incidentId}/uploadFiles`, formData).toPromise();
  }
  
  removeFiles(filesIds: Array<string>) {
    return this.http.post<any>(`${this.workerIncidentUrl}/filesToRemove`, { workerIncidentFileIds: filesIds }).toPromise();
  }

  remove(incident: Incident) {
    return this.http.delete(`${this.workerIncidentUrl}/${incident.id}`);
  }

  getIncidentTypes() {
    return this.http.post<any>(`${this.apiUrl}/prefillProvider/getPrefillData`, {
      includeRequests: [
        {
          entityName: "IncidentType",
          keyAttributeName: "Id",
          valueAttributeName: "Name",
          includeAs: "incidentType"
        }
      ]
    }).pipe(
      map(res => res.prefillData),
      map((data: any) => {
        return data[0].filterData.map(item => {
          return { text: item.value, value: item.key };
        });
      })
    );
  }

  openIncidentsForm(workerId: number, incident?: Incident): void {
    this.dialog.open(IncidentFormComponent, {
      width: "600px",      
      autoFocus: false,
      data: {
        incident: incident,
        workerId: workerId, 
        onUpdate: (error) => {
          if (error) {
            const errorMessage = error?.error?.error?.details || `There was a problem ${incident ? 'updating' : 'creating'} the incident. Please try again.`
            this._notificationService.showError(errorMessage);
          } else {
            const successMessage = `The incident was successfully ${incident ? 'updated' : 'created'}.`;
            this._notificationService.showSuccess(successMessage);
            this.dialog.closeAll();
          }
        }
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationService } from '../../../core/services/notification.service';
import { InputFile } from '../../interfaces/input-file.interface';

@Component({
  selector: 'app-input-files',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './input-files.component.html'
})
export class InputFilesComponent implements OnInit {

  @Input() initialFiles: Array<any>;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() showList: boolean;
  
  @Input() acceptedTypes: string;
  @Input() limitSize: number;
  @Input() limitCount: number;

  @ViewChild('inputFile', { static: true }) inputFile: ElementRef;
  @Output() updated: EventEmitter<InputFile[]>;
  @Output() deleted: EventEmitter<InputFile>;
  
  files: Array<InputFile>;

  constructor(
    private _notificationService: NotificationService
  ) {
    this.files = [];
    this.initialFiles = [];
    this.label = "Archivos";
    this.acceptedTypes = "";
    this.limitSize = 25;
    this.limitCount = 10;
    this.updated = new EventEmitter<InputFile[]>();
    this.deleted = new EventEmitter<InputFile>();
  }

  ngOnInit(): void {
    this.files = [...this.initialFiles];
    this.filesChanges();
  }

  private filesChanges(): void {
    this.inputFile.nativeElement.onchange = (event: any) => {
      const uploadFiles: Array<File> = Array.from(<FileList>event.target.files);
      const validFiles: Array<File> = this.validateFiles(uploadFiles);

      if (this.files.length + validFiles.length <= this.limitCount) {
        if (uploadFiles.length !== validFiles.length) {
          this._notificationService.showError(`Some files fail validations`);  
        }
        const filesUpdated: Array<InputFile> = this.mapFiles(validFiles);
        this.files.push(...filesUpdated);
        this.inputFile.nativeElement.value = null;
        this.updated.emit(this.files);
      } else {
        this._notificationService.showError(`Can't upload more than ${this.limitCount} files`);
      }
    }
  }

  private validateFiles(files: Array<File>): Array<File> {
    const allowedTypes: Array<string> = this.acceptedTypes.split(', ').map((t) => { return t.replace('.', ''); });
    return files.filter((f) => {
      const fileType: string = f.type.split('/')[1];
      return f.size <= (this.limitSize * 100000) && allowedTypes.includes(fileType);
    });
  }

  private mapFiles(files: Array<File>): Array<InputFile> {
    return files.map((f) => {
      return {
        name: f.name,
        file: f,
        url: null,
        id: null
      };
    });
  }

  removeFile(index: number): void {
    const deletedFile: InputFile = this.files[index];
    this.files.splice(index, 1);
    this.updated.emit(this.files);
    this.deleted.emit(deletedFile);
  }

}

<label [ngClass]="{ 'opacity-50': disabled }" class="w-full mb-2 base-input-label">
  <p>
    {{ label }}
    @if (required) {
      <span>*</span>
    }
  </p>
</label>

<div class="flex items-center">
  <input #inputFile
         type="file"
         id="inputFiles"
         [accept]="acceptedTypes"
         [disabled]="disabled"
         [multiple]="multiple"
         style="display: none;">

  <label for="inputFiles"
         [ngClass]="{ 'opacity-25 cursor-default': disabled }"
         class="cursor-pointer secondary-button">
    Upload files
  </label>

  <mat-icon [matTooltip]="'Allowed extensions: ' + acceptedTypes  + '\n Maximum file size: ' + limitSize + 'MB \n Maximum number of files: ' + limitCount"
            [matTooltipClass]="'mat-tooltip-info'"
            class="ml-2 icon-action">
    info
  </mat-icon>
</div>

@if (showList) {
  <div class="mt-4">
    @for (file of files; track $index; let odd = $odd) {
      <div class="flex justify-between items-center bg-white border border-gray-200 p-2"
           [ngClass]="{ 'bg-gray-200': odd }"
           data-testid="row-file">
        <span>{{ file.name }}</span>
        <button (click)="removeFile($index)"
                [disabled]="disabled"
                [ngClass]="{ 'cursor-default': disabled }"
                class="flex items-center icon-action">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }
  </div>
}
import { AsyncPipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Select, Store } from "@ngxs/store";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { enterAnimation } from "src/app/core/animations";
import { NotificationService } from "src/app/core/services/notification.service";
import { LoadUnions } from "src/app/features/admin/unions/state/unions.actions";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { DatePickerComponent } from "src/app/shared/components/date-picker/date-picker.component";
import { DialogFooterComponent } from "src/app/shared/components/dialog-footer/dialog-footer.component";
import { DropdownComponent } from "src/app/shared/components/dropdown/dropdown.component";
import { InputFilesComponent } from "src/app/shared/components/input-files/input-files.component";
import { TimeInputComponent } from "src/app/shared/components/time-input/time-input.component";
import { InputFile } from "src/app/shared/interfaces/input-file.interface";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { Suspension } from "../../interfaces/suspension.model";
import { AddWorkerSuspension, UpdateWorkerSuspension } from "../../state/worker.actions";

@Component({
	selector: "app-suspension-form",
	standalone: true,
	imports: [
		ReactiveFormsModule,
		AsyncPipe,
		DropdownComponent,
		TimeInputComponent,
		DatePickerComponent,
		InputFilesComponent,
		DialogFooterComponent
	],
	templateUrl: "./suspension-form.component.html",
	animations: [enterAnimation]
})
export class SuspensionFormComponent implements OnInit, OnDestroy {
	
	@Select(state => state.worker.saving) isSaving$: Observable<boolean>;
	@Select(state => state.unions.items) unions$: Observable<any>;
	@Select(state => state.prefillData.allEmployers) employers$: Observable<any>;
	@Select(state => state.prefillData.allSuspensionTypes) suspensionTypes$: Observable<SelectOption>;
	@Select(state => state.admin.errors.saving) error$: Observable<string | boolean>;
	@Select(state => state.worker.selectedWorker) selectedWorker$: Observable<string>;

	title: string;
	unions: SelectOption[];
	employers: SelectOption[];
	hoursOptions: SelectOption[];
	suspensionForm: UntypedFormGroup;
	suspensionFiles: Array<InputFile>;
  filesIdsToDelete: Array<string>;
	private unsuscribe$: Subject<void>;

	constructor(
		private dialogRef: MatDialogRef<SuspensionFormComponent>,
		private store: Store,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		@Inject(MAT_DIALOG_DATA) public data: {
			suspension: Suspension,
			workerId: number
		}
	) {
		this.title = '';
		this.unions = [];
		this.employers = [];
		this.hoursOptions = [
			{ value: 12, text: "12 hours" },
			{ value: 24, text: "24 hours" },
			{ value: 36, text: "36 hours" },
			{ value: 48, text: "48 hours" },
			{ value: 72, text: "72 hours" }
		];
		this.suspensionFiles = [];
    this.filesIdsToDelete = [];
		this.unsuscribe$ = new Subject<void>();
		this.dialogRef.disableClose = true;
		this.subscribeBackdropDialog();
		this.store.dispatch(new LoadUnions());
	}

	private subscribeBackdropDialog(): void {
		this.dialogRef.backdropClick().pipe(
			takeUntil(this.unsuscribe$)
		).subscribe(result => {
			this.dialog.open(ConfirmDialogComponent, {
				width: "400px",
				data: {
					message: `Are you sure you want to leave?`,
					onConfirm: () => {
						this.dialog.closeAll();
						this.dialogRef.close();
					}
				}
			});
		});
	}

	ngOnInit(): void {
		this.createForm();
		this.setTitle();
		this.subscribeSelectedWorker();
		this.subscribeEmployers();
	}

	private createForm(): void {
		this.suspensionForm = new UntypedFormGroup({
			"employerId": new UntypedFormControl(this.data.suspension ? this.data.suspension.employerId : null, [Validators.required]),
			"unionId": new UntypedFormControl(this.data.suspension ? this.data.suspension.unionId : null, [Validators.required]),
			"suspensionTypeId": new UntypedFormControl(this.data.suspension ? this.data.suspension.suspensionTypeId : null, [Validators.required]),
			"numberOfHours": new UntypedFormControl(null),
			"effectiveDate": new UntypedFormControl(this.data.suspension ? new Date(this.data.suspension.effectiveDate) : null, [Validators.required]),
			"effectiveTime": new UntypedFormControl(this.data.suspension ? moment(this.data.suspension.effectiveDate).format('HHmm') : null),
			"expirationDate": new UntypedFormControl(this.data.suspension ? new Date(this.data.suspension.expirationDate) : null, [Validators.required]),
			"expirationTime": new UntypedFormControl(this.data.suspension ? moment(this.data.suspension.expirationDate).format('HHmm') : null),
			"comments": new UntypedFormControl(this.data.suspension ? this.data.suspension.comments : '')
		});
		this.subscribeNumberOfHours();
		this.subscribeEffectiveDate();
		this.subscribeEffectiveTime();
		// this.subscribeExpirationDate();
		// this.subscribeExpirationTime();
		if (this.data?.suspension) {
      this.suspensionFiles = this.data.suspension.suspensionFiles.map((f) => {
        return {
          name: f.fileName,
          file: null,
          url: f.fileUri,
          id: f.id,
        };
      })
    }
	}

	private subscribeNumberOfHours(): void {
		this.suspensionForm.get('numberOfHours').valueChanges.subscribe(
			(value) => {
				if (value) {
					this.setExpirationFields();
				}
			}
		);
	}

	private subscribeEffectiveDate(): void {
		this.suspensionForm.get('effectiveDate').valueChanges.subscribe(
			(value) => {
				if (value) {
					this.setExpirationFields();
				}
			}
		);
	}

	private subscribeEffectiveTime(): void {
		this.suspensionForm.get('effectiveTime').valueChanges.subscribe(
			(value) => {
				this.setExpirationFields();
			}
		);
	}

	private setExpirationFields(): void {
		const effectiveDate: UntypedFormControl = <UntypedFormControl>this.suspensionForm.get('effectiveDate');
		const numberOfHours: string = this.suspensionForm.get('numberOfHours').value;

		if (effectiveDate.value && effectiveDate.valid && numberOfHours) {
			const effectiveMoment: moment.Moment = this.getMoment('effective');
			effectiveMoment.add(parseInt(numberOfHours), 'hours');
	
			const expirationDate: string = effectiveMoment.clone().startOf('day').format();
			this.suspensionForm.get('expirationDate').setValue(new Date(expirationDate), { emitEvent: false });
			
			const expirationTime: string = effectiveMoment.format('HHmm');
			this.suspensionForm.get('expirationTime').setValue(expirationTime, { emitEvent: false });
		}
	}

	private getMoment(type: 'effective' | 'expiration'): moment.Moment {
		const date: string = this.suspensionForm.get(`${type}Date`).value;
		if (date) {
			let momenta: moment.Moment = moment(date).startOf('day')

			const time: string = this.suspensionForm.get(`${type}Time`).value;
			if (time) {
				const hours: number = parseInt(time.slice(0, 2));
				const minutes: number = parseInt(time.slice(2, 4));
				momenta.add(hours, 'hours').add(minutes, 'minutes');
			} else if (type === "expiration") {
				momenta = momenta.clone().endOf('day');
			}
			return momenta;
		}
		return null;
	}

	// private subscribeExpirationDate(): void {
	// 	this.suspensionForm.get('expirationDate').valueChanges.subscribe(
	// 		(value) => {
	// 			console.log('EMITTED');
	// 			this.suspensionForm.get('numberOfHours').setValue(null, { emitEvent: false });
	// 		}
	// 		);
	// 	}
		
	// 	private subscribeExpirationTime(): void {
	// 		this.suspensionForm.get('expirationTime').valueChanges.subscribe(
	// 			(value) => {
	// 			console.log('EMITTED');
	// 			this.suspensionForm.get('numberOfHours').setValue(null, { emitEvent: false });
	// 		}
	// 	);
	// }

	private setTitle(): void {
		this.title = this.data.suspension ? 'Edit Suspension' : 'Create New Suspension';
	}

	private subscribeSelectedWorker(): void {
		this.selectedWorker$.pipe(
			takeUntil(this.unsuscribe$)
		).subscribe((response) => {
			if (response) {
				const worker = JSON.parse(response);
				if (worker.workerUnions.length > 0) {
					this.unions = worker.workerUnions.map((w: any) => {
						return {
							text: w.union.unionCode + ' - ' + w.union.unionDescription,
							value: w.union.id
						}
					})
				}
				if (!this.data.suspension) {
					this.unions.unshift({ text: 'All Unions', value: 'all' })
				}
			}
		})
	}

	private subscribeEmployers(): void {
		this.employers$.pipe(
			takeUntil(this.unsuscribe$)
		).subscribe((response) => {
			this.employers = [...response];
			if (!this.data.suspension) {
				this.employers.unshift({ text: 'All Employers', value: 'all' })
			}
		})
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	setFiles(files: Array<InputFile>): void {
		this.suspensionFiles = files;
	}

	deleteFile(file: InputFile): void {
    if (file.id) {
      this.filesIdsToDelete.push(file.id); 
    }
  }

	private getCompleteDatetime(field: string): string {
		const date: Date = this.suspensionForm.get(`${field}Date`).value;
		
		let time: string = this.suspensionForm.get(`${field}Time`).value;
		if (!time) {
			if (field === 'effective') {
				time = '0000';
			} else if (field === 'expiration') {
				time = '2359';
			}
		}

		date.setHours(parseInt(time.slice(0, 2)));
		date.setMinutes(parseInt(time.slice(2, 4)));
	
		let userTimezoneOffset = date.getTimezoneOffset() * 60000;
		const datetime = new Date(date.getTime() - userTimezoneOffset);
		return datetime.toISOString().replace("Z", "");
	}

	save(): void {
		this.dialogRef.disableClose = true;

		const suspension: Suspension = {
			comments: this.suspensionForm.value.comments,
			effectiveDate: this.getCompleteDatetime('effective'),
			expirationDate: this.getCompleteDatetime('expiration'),
			workerId: this.data.workerId,
			suspensionTypeId: this.suspensionForm.value.suspensionTypeId
		};
		const files: Array<File> = this.suspensionFiles.map((f) => { return f.file; });

		if (this.data.suspension) {
			suspension.id = this.data.suspension.id;
			suspension.employerId = this.suspensionForm.value.employerId,
			suspension.unionId = this.suspensionForm.value.unionId,
			this.store.dispatch(
				new UpdateWorkerSuspension(
					suspension,
					files.filter((f) => { return !!f }), this.filesIdsToDelete,
					this.callbackSuccess.bind(this)
				)
			);
		} else {
			suspension.employerIds = this.getEmployerIds();
			suspension.unionIds = this.getUnionIds();
			this.store.dispatch(
				new AddWorkerSuspension(
					suspension,
					files,
					this.callbackSuccess.bind(this)
				)
			);
		}
	}

	private callbackSuccess(error): void {
		if (error) {
			const errorMessage: string = error?.error?.error?.details ? 
				error.error.error.details : 
				this.data.suspension ? 
					"There was a problem updating the suspension. Please try again." : 
					"There was a problem creating the suspension. Please try again.";
			this.notificationService.showError(errorMessage);
		} else {
			const successMessage: string = this.data.suspension ? "The suspension was successfully updated." : "The suspension was successfully created.";
			this.notificationService.showSuccess(successMessage);
			this.dialog.closeAll();
		}
	}

	private getEmployerIds(): Array<number> {
		if (this.suspensionForm.value.employerId == 'all') {
			return this.employers
				.filter((item: SelectOption) => { return item.value != 'all' })
				.map((item: SelectOption) => { return item.value });
		}
		return [this.suspensionForm.value.employerId];
	}

	private getUnionIds(): Array<number> {
		if (this.suspensionForm.value.unionId == 'all') {
			return this.unions
				.filter((item: SelectOption) => { return item.value != 'all' })
				.map((item: SelectOption) => { return item.value });
		}
		return [this.suspensionForm.value.unionId];
	}

	ngOnDestroy(): void {
		this.unsuscribe$.next();
		this.unsuscribe$.complete();
	}
}

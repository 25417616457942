import { Permission } from "src/app/core/enums/permission.enum";
import { NavigationItem } from "src/app/shared/interfaces/navigation-item.interface";
import { WorkerProfileStatus } from "../enums/worker-profile-status.enum";

const COMMON_PERMISSIONS_ALLOWED: Permission[] = [
  Permission.Administrator,
  Permission.UnionAdministrator,
  Permission.UnionAdminReadonly,
  Permission.UnionDispatcher
];

export const WORKER_PROFILE_TABS: { [key: number]: NavigationItem[] } = {
	[WorkerProfileStatus.Creating]: [
		{
			text: "Profile",
			routerLink: ["profile"],
			disabled: false
		},
		{
			text: "Worker Information",
			routerLink: ["worker-information"],
			disabled: true
		}
	],
	[WorkerProfileStatus.Editing]: [
		{
			text: "Profile",
			routerLink: ["profile"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Profile History",
			routerLink: ["profile-history"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Work History",
			routerLink: ["work-history"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Worker Information",
			routerLink: ["worker-information"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Certifications",
			routerLink: ["certifications"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Suspensions",
			routerLink: ["suspensions"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Communications",
			routerLink: ["communications"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Incidents",
			routerLink: ["incidents"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Payments",
			routerLink: ["payments"],
			permissions: COMMON_PERMISSIONS_ALLOWED
		},
		{
			text: "Debits",
			routerLink: ["debits"],
			permissions: [
				Permission.Administrator,
				Permission.UnionAdministrator,
				Permission.StevedoringAssociation
			]
		},
		{
			text: "Documents",
			routerLink: ["documents"],
			permissions: [
				Permission.Administrator,
				Permission.UnionAdministrator,
				Permission.UnionDispatcher
			]
		}
	],
	[WorkerProfileStatus.AddingUnion]: [
		{
			text: "Worker Information",
			routerLink: ["worker-information"]
		}
	]
}